import { handleError } from 'common/api/handleError';
import { useGetPackHousesQuery } from 'common/api/packHouse.api';
import { useGetPickScheduleSummaryQuery } from 'common/api/pickSchedulingApi';
import { CsvButton } from 'common/components/CsvButton/CsvButton';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { WeekSwitcher } from 'common/components/WeekSwitcher';
import { WeekSwitcherTextStyle } from 'common/components/WeekSwitcher/styles';
import {
  WeekSwitcherIds,
  useWeekSwitcherState,
} from 'common/components/WeekSwitcher/useWeekSwitcherState';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader, red } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { boxShadow } from 'common/styles/page';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DayInfo, getWeekDay } from 'utils/dateTimeHelpers';
import { generateWeekEndString } from 'utils/helperFunction';
import { PackHousePickScheduleTable } from '../components/PackHousePickScheduleTable';
import { ApprovalRow } from '../components/PickSchedule/ApprovalRow';
import { DailyTotalRow } from '../components/PickSchedule/DailyTotalRow';
import { DaySelector } from '../components/PickSchedule/DaySelector';
import { useDaySelector } from '../hooks/useDaySelector';
import { PackHousePickScheduleTableData } from '../hooks/usePackHousePickScheduleTableData';
import {
  PickSchedKeys,
  formatCsvData,
  pickSchedHeaders,
} from '../utils/preparePickSchedCsvData';
import { TitleAndCsvBtn } from './styles';

function weekdaysToRange(week: DayInfo[]) {
  return `${week[0].month}-${week[0].day} to ${week[week.length - 1].month}-${
    week[week.length - 1].day
  }`;
}

const StyledContainer = styled.div`
  box-shadow: ${boxShadow};
  border-radius: 8px;
  padding: ${FormPadding};

  h1 {
    font-family: KanitLight;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.0075em;
    color: ${blueShadeHeader};
  }

  .error {
    color: ${red};
  }

  @media (max-width: ${mobile}) {
    padding: 0;
  }
`;

const Header = styled.div`
  @media (max-width: ${mobile}) {
    padding: 5px;
  }
`;

const WeekRow = styled.div`
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 1.25rem;

  h3 {
    ${WeekSwitcherTextStyle}
    margin: 0;
  }

  @media (max-width: ${mobile}) {
    flex-direction: column;
    align-items: flex-start;

    div {
      display: flex;
      align-items: center;

      h3 {
        margin-right: 10px;
      }
    }
  }
`;

export type PackHousePickSchedule = {
  packHouseCode: string;
  rows: PackHousePickScheduleTableData[];
  totals: {
    scheduledTotal: number;
    pickedTotal: number;
    binsLeftTotal: number;
  };
};

export const PickScheduleView: FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const { state: week } = useWeekSwitcherState(WeekSwitcherIds.PickSchedule);
  const { offset } = useDaySelector();
  const selectedDate: {
    withYear: string;
    noYear: string;
    scheduleDay: string;
  } = useMemo(() => {
    const currentDate = new Date(week.startDate);
    currentDate.setDate(currentDate.getDate() + offset.value);
    const month = currentDate.getMonth() + 1;
    const date = currentDate.getDate();
    const noYear = `${month}-${date}`;

    // - These two dates must be in the same timezone, eg: both local, so we
    //   retrieve exactly what the user is seeing.
    // - We must replace slashes because that's what the backend needs.
    return {
      withYear: currentDate.toLocaleDateString('en-US').replaceAll('/', '-'),
      noYear,
      scheduleDay: getWeekDay(currentDate),
    };
  }, [offset.value, week.startDate]);

  const {
    data: packHouses,
    isLoading,
    error: fetchPHError,
  } = useGetPackHousesQuery();
  const {
    data: summaryData,
    isFetching: isSummaryFetching,
    error: fetchSummaryError,
  } = useGetPickScheduleSummaryQuery(selectedDate.withYear, {
    refetchOnMountOrArgChange: true,
  });
  const [pickSchedules, setPickSchedules] = useState<PackHousePickSchedule[]>(
    [],
  );
  const [csvData, setCsvData] = useState<Record<PickSchedKeys, string>[]>([]);
  const [dataLoadingCount, setDataLoadingCount] = useState(0);

  useEffect(() => {
    if (fetchPHError) handleError(fetchPHError, 'Unable to load pack houses.');
  }, [fetchPHError]);

  useEffect(() => {
    if (fetchSummaryError)
      handleError(fetchSummaryError, 'Unable to load daily total.');
  }, [fetchSummaryError]);

  return (
    <StyledContainer>
      <Header>
        <TitleAndCsvBtn>
          <h1>Pick Schedule</h1>
          <CsvButton
            headers={pickSchedHeaders(
              `${selectedDate.scheduleDay} ${selectedDate.noYear}`,
            )}
            data={csvData}
            filename={`${generateWeekEndString(week.weekdays[6])}_${
              selectedDate.scheduleDay
            }_pick_schedule`}
            onClick={() => {
              // Stop component logic if any data is being retrieved
              if (dataLoadingCount) return false;
              setCsvData(
                formatCsvData(
                  {
                    scheduledTotal: summaryData?.scheduledTotal || 0,
                    pickedTotal: summaryData?.pickedTotal || 0,
                  },
                  pickSchedules,
                ),
              );
              return true;
            }}
            isLoading={!!dataLoadingCount}
          />
        </TitleAndCsvBtn>
        <WeekRow>
          {!isMobile ? (
            <>
              <h3>{week.startDate.getFullYear()} |</h3>
              <WeekSwitcher identifier={WeekSwitcherIds.PickSchedule} />
              <h3>| Pick {weekdaysToRange(week.weekdays)}</h3>
            </>
          ) : (
            <>
              <div>
                <h3>{week.startDate.getFullYear()} |</h3>
                <WeekSwitcher identifier={WeekSwitcherIds.PickSchedule} />
              </div>
              <h3>Pick {weekdaysToRange(week.weekdays)}</h3>
            </>
          )}
        </WeekRow>
        <DailyTotalRow
          isFetching={isSummaryFetching}
          fetchingError={fetchSummaryError}
          scheduledTotal={summaryData?.scheduledTotal}
          pickedTotal={summaryData?.pickedTotal}
        />
        {summaryData && summaryData.scheduledTotal > 0 && (
          <ApprovalRow
            isFetching={isSummaryFetching}
            fetchingError={fetchSummaryError}
            isApproved={summaryData.isApproved}
            summaryId={summaryData.id}
          />
        )}
      </Header>
      <DaySelector date={selectedDate.noYear} />
      <WithLoadingOverlay isLoading={isLoading}>
        {packHouses?.map(house => (
          <PackHousePickScheduleTable
            key={house.id}
            packHouse={house}
            selectedDate={selectedDate}
            setDataLoadingCount={setDataLoadingCount}
            setPickSchedules={setPickSchedules}
          />
        ))}
      </WithLoadingOverlay>
    </StyledContainer>
  );
};
